:root {
  --bmh-dark-blue: #005596;
  --bmh-dark-blue-light: #00559683;
  --bmh-dark-blue-hover: #518ab6;
  --bmh-light-blue: #8bd1f3;
  --bmh-light-blue-hover: #a6d9f3;
  --bmh-red: #eb1515;
  --bmh-green: #bfd72e;
  --bmh-green-light: #dff175;
  --bmh-yellow: #fff200;
  --bmh-grey: #A8A8A8FF;
  --bmh-text: #535353;
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

h6 {
  margin: 0 !important;
  top: 50%;
}

.main {
  margin-top: 32px;
  margin-bottom: 64px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1200px;
  min-width: 700px;
}

.nobr {
  white-space: nowrap
}

.navbar {
  background: linear-gradient(90deg, var(--bmh-green), var(--bmh-green));
  left: 0;
  flex-direction: row !important;
}

.navbar .container, .logo-container {
  max-width: 1200px !important;
  min-width: 400px !important;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
}

.navbar-toggler {
  color: var(--bmh-dark-blue) !important;
}

.nav-link{
  color: var(--bmh-dark-blue) !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.nav-link:hover{
  color: var(--bmh-dark-blue-hover) !important;
}

.nav-btn {
  background-color: transparent !important;
  box-shadow: none !important;
}

.nav-link-right {
  margin-left: auto !important;
  float: right !important;
}

p {
  margin-bottom: 0 !important;
}

h3 {
  margin-top: 48px !important;
  margin-bottom: 32px !important;
}

.btn {
  background-color: var(--bmh-green) !important;
  color: var(--bmh-dark-blue) !important;
  box-shadow: 0 0 0 0 !important;
  font-weight: 600 !important;
}

.btn:hover {
  color: var(--bmh-dark-blue-hover) !important;
}

.btn-light {
  background-color: var(--bmh-green-light) !important;
  color: var(--bmh-dark-blue-hover) !important;
}

.delete {
  background-color: var(--bmh-red)!important;
}

.btn-icon {
  margin-right: 8px !important;
}

.logo-text {
  margin-left: 8px;
}

.dropdown-toggle {
  color: white !important;
}

.dropdown-toggle:hover {
  color: white !important;
}

.file-upload-container {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
}

.file-upload-container .form-control {
  width: 70%;
  min-width: 250px;
}

.delete-file {
  color: #1266f1;
  border: 0;
  background-color: white;
}

.delete-file:hover {
  color: #0e52c1;
}

.header {
  width: 100%;
  display: flex;
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.header-btn {
  height: 36px;
  margin-bottom: 0;
  margin-left: auto;
  float: right !important;
}

.download-btn {
  background-color: white !important;
  border: 0 !important;
  box-shadow: inset 0 0 0 #e0e0e0;
  color: var(--bmh-text);
}

.options-btn {
  background-color: white !important;
  border: 0 !important;
  box-shadow: inset 0 0 0 #e0e0e0;
  color: var(--bmh-text);
}

.btn-group {
  box-shadow: inset 0 0 0 #e0e0e0 !important;
}

.dropend .btn {
  padding: 0;
}

.edit-btn {
  background-color: white !important;
  border: 0 !important;
  box-shadow: inset 0 0 0 #e0e0e0;
  color: var(--bmh-grey);
}

.edit-btn:hover {
  color: var(--bmh-text);
}

.input-label {
  margin-top: 16px;
  margin-bottom: 0;
}

.input {
  margin-bottom: 32px;
  background-color: white !important;
}

.validation-label {
  margin-top: -32px;
  margin-bottom: 32px !important;
  color: var(--bmh-red);
}

.footer {
  height: 36px;
  margin-top: 16px;
  margin-bottom: 32px !important;
  float: right;
}

.left {
  float: left !important;
}

.checklist-icon {
  margin-right: 15px !important;
}